<template>
  <div class="row">
    <div class="col-12 modal-box">
      <div class="close-button mb-4">
        <div class="btn btn-link buttonClose d-inline-block" @click="onClose()">
          <span><i class="text-primary rc-Ativo-36"> </i></span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12">
          <div class="content">
            <p>
              DICA ROYAL:
            </p>
            <p>
              Procure por datas próximas para encontrar mais opções de valores em tempo real.<br/>
            </p>
            <p>
              Infelizmente, o mercado está instável e os armadores não estão disponibilizando fretes com longa validade.
            </p>
            <md-checkbox v-model="notShowAgain" @change="onToggle">{{ $t('Não mostrar essa mensagem novamente') }}</md-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InfoModal',
  data () {
    return {
      notShowAgain: false
    }
  },
  methods: {
    onToggle (val) {
      global.instanceApp.$Cookie.set('_proposal_not_show_period_situation_', val)
    },
    onClose () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
  .modal-box{
    padding: 50px;
    padding-bottom: 30px;
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
  }

  p {
    text-align: justify;
  }

  .content p {
    font-size: 14px;
    color: #4D4F5C;
    font-family: 'pluto_sanslight';
    /*padding-bottom: 20px;*/
  }
</style>

<style>
  .modal-box .md-checkbox .md-checkbox-label {
    font-size: 85%;
    text-align: left;
    font-family: 'pluto_sanslight';
  }
</style>
