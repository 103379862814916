<template>
  <div>
    <div class="px-5 py-2">
      <loading :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="fullPage"></loading>
      <div class="row justify-content-md-center">
        <div class="col-12">
          <filter-side-bar>
            <template slot="ContentSideBar">
              <form id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
                <h5 class="mb-0">Filtrar por:</h5>

                <!-- Vendedor -->
                <div class="mt-5">
                  <div>
                    <multiple-select labelField="nome" :options="employeeList" :id-field="'id_employee'" :placeholder="this.$i18n.t('form.task.seller')" :fetchOptions="employeeFetchOptions" :value="filters.filEmployees" />
                  </div>
                </div>
                <!-- Criado por -->
                <div v-if="showEmployeeFilter">
                  <div>
                    <multiple-select labelField="nome" :options="userList" :id-field="'id'" :placeholder="this.$i18n.t('form.task.created_by')" :fetchOptions="userFetchOptions" :value="filters.filUsers" />
                  </div>
                </div>

                <div>
                  <div>
                    <multiple-select :options="customerList" :id-field="'id_customer'" :placeholder="this.$i18n.t('form.customer.label')" :fetchOptions="customerFetchOptions" :value="filters.filCliente" />
                  </div>
                </div>

                <div class="py-1 text-center">
                  <div class="md-form mb-0">
                    <div class="row">
                      <div class="col-6">
                        <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                        </button>
                      </div>
                      <div class="col-6">
                        <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">
                          {{ $t('filter.reset') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </filter-side-bar>
        </div>

        <!--   Search Bar   -->
        <div class="col-12 col-md-12 mt-5 mb-3">
          <search-bar v-model="filterData" v-on:search="onSearch" :equipment-list="equipmentList"/>
        </div>
      </div>
    </div>

    <!-- Filter Status Bar -->
    <div class="col-12 mb-3 no-padding">
      <div class="card no-bottom-margin">
        <div class="section-sub-menu">
          <div class="section-sub-menu">
            <ul class="nav line-tabs borderless flex-center">
              <li class="nav-item">
                <a class="nav-link" v-bind:class="{ active: filter.id_status === null }"
                   v-on:click="applyStatusFilter(null)" data-toggle="tab">Todos</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" v-bind:class="{ active: filter.id_status === 1 }"
                   v-on:click="applyStatusFilter(1)" data-toggle="tab">{{ $t('form.proposal.status.draft') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" v-bind:class="{ active: filter.id_status === 2 }"
                   v-on:click="applyStatusFilter(2)" data-toggle="tab">{{ $t('form.proposal.status.waiting')
                  }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" v-bind:class="{ active: filter.id_status === 3 }"
                   v-on:click="applyStatusFilter(3)" data-toggle="tab">{{ $t('form.proposal.status.confirmed') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" v-bind:class="{ active: filter.id_status === 4 }"
                   v-on:click="applyStatusFilter(4)" data-toggle="tab">{{ $t('form.proposal.status.not_confirmed') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" v-bind:class="{ active: filter.id_status === 7 }"
                   v-on:click="applyStatusFilter(5)" data-toggle="tab">{{ $t('form.proposal.status.cancel') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" v-bind:class="{ active: filter.id_status === 9 }"
                   v-on:click="applyStatusFilter(6)" data-toggle="tab">{{ $t('form.proposal.status.expired') }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- END Filter Status Bar -->

    <div class="abaixo px-5 py-2">
      <div class="row justify-content-md-center">
        <!-- Bloco com os Filtros -->
        <div class="col-12 p-2 mb-3">
<!--          <filter-container :activeFilterList="activeFilterList" />-->
        </div>

        <div class="col-12 col-sm-12 p-2">
          <div class="row">
            <div class="col-12 p-0">
              <div class="card p-0">
                <div class="card-body p-0">
                  <!--  Actions -->
                  <div class="flexbox mb-3">
                    <div class="d-flex align-items-center">
                    </div>
                    <div class="btn-group" role="group" aria-label="false">
  <!--                    <router-link :to="{name: 'ProposalSearch'}" class="btn btn-outline-light  pull-right">-->
  <!--                      Novo-->
  <!--                    </router-link>-->
                    </div>
                  </div>
                  <!--  End Actions -->

                  <!-- Table -->
                  <!--<div v-on:click="toggleColumn">Config</div>-->
                  <div class="table-responsive ">
                    <vuetable ref="vuetable"
                        :noDataTemplate="$t('noting to result')"
                        :api-url="proposalEndpoint"
                        :fields="fieldsTable"
                        :sort-order="sortOrder"
                        :css="css.table"
                        pagination-path="pagination"
                        :per-page="20"
                        :multi-sort="multiSort"
                        :append-params="filter"
                        multi-sort-key="shift"
                        :http-options="httpOptions"
                        track-by="id"
                        @vuetable:pagination-data="onPaginationData"
                        @vuetable:loading="onLoading"
                        @vuetable:loaded="onLoaded"
                        @vuetable:load-error="handleLoadError"
                        @vuetable:cell-clicked="onRowClicked">
                      <template slot="actions" slot-scope="props">
                        <div class="custom-actions">
                          <a v-if="props.rowData.situacao !== 1" class="btn btn-link btn-padding" :title="$t('actions.view')"
                             v-bind:href="mountLink('ProposalView', props.rowData)">
                            <i class="text-primary  rc-Ativo-210 font-20"></i>
                          </a>
                          <a v-if="props.rowData.situacao === 1" class="btn btn-link btn-padding" :title="$t('actions.edit')"
                            v-bind:href="mountLink('ProposalCreate', props.rowData)">
                            <i class="text-primary  ti-pencil font-20"></i>
                          </a>
                          <a v-if="props.rowData.situacao === 1" class="btn btn-link btn-padding" :title="$t('actions.delete')"
                            v-on:click="onDelete(props.rowData)">
                            <i class="text-primary  ti-trash font-20"></i>
                          </a>
                          <a class="btn btn-link btn-padding" :title="$t('actions.add_contacts')"
                            v-on:click="onAddContacts(props.rowData)">
                            <i class="text-primary  ti-email font-20"></i>
                          </a>
                        </div>
                      </template>
                    </vuetable>

                  </div>
                  <vuetable-pagination ref="pagination"
                    :css="css.pagination"
                    @vuetable-pagination:change-page="onChangePage">
                  </vuetable-pagination>
                  <!-- END Table -->

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Vuetable, { VuetablePagination } from 'vuetable-2'
import FilterSideBar from '@/components/FilterSideBar.vue'
// import FilterContainer from '@/components/FilterContainer'
import TaskLog from '@/views/Schedules/TaskLog.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import ContactModal from './ContactModal'
import InfoModal from './InfoModal'
// Components
import SearchBar from './SearchBar'

// MultipleSelect
import MultipleSelect from '@/components/Forms/MultipleSelect.vue'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import CustomerService from '@/services/CustomerService'
import EmployeeService from '@/services/EmployeeService'
import ProposalService from '@/services/ProposalService'
import EquipmentService from '@/services/EquipmentService'
import moment from 'moment'

export default {
  name: 'ProcessList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.proposal') + ' - %s'
    }
  },
  data () {
    return {
      filterData: {
        operation: {
          id: 2,
          nome: 'IMPORT'
        },
        modality: {
          id: 2,
          nome: 'Marítimo - FCL'
        },
        incoterm: {
          id: 2,
          nome: 'FOB'
        },
        commoditie: {
          id: null,
          nome: null
        },
        origin: {
          id: null,
          nome: null
        },
        destination: {
          id: null,
          nome: null
        },
        estimatedDate: null,
        equipments: [],
        cbm: null,
        peso_bruto: null,
        showShipowner: null,
        sortBy: {
          id: 1,
          nome: 'Preço mais baixo'
        },
        customer: {
          id: null,
          id_table_origin: null,
          name: null,
          cnpj: null
        }
      },
      equipmentList: [],
      employeeList: [],
      userList: [],
      customerList: [],
      isLoading: false,
      fullPage: true,
      selectedProcess: null,
      processDetail: false,
      proposalEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/proposal',
      multiSort: true,
      activeFilterList: {
      },
      filter: {},
      sortOrder: [
        { field: 'referencia', direction: 'desc' }
      ],
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  components: {
    SearchBar,
    FilterSideBar,
    Vuetable,
    VuetablePagination,
    Loading,
    MultipleSelect
  },
  // Apply filters to local filter
  beforeMount () {
    this.filter.internal_code = this.$route.params.process
    this.filters.filProcesso = this.$route.params.process

    this.buildFilters()
  },
  created () {
    let _this = this
    // Aplica filtros aplicados anteriormente
    let previousFilters = global.instanceApp.$Cookie.get('_proposal_last_search_')

    if (previousFilters !== undefined) {
      let today = moment()
      _this.filterData = JSON.parse(previousFilters)

      let selectedDate = moment(_this.filterData.estimatedDate)

      let days = selectedDate.diff(today, 'days')

      if (days < 1) {
        _this.filterData.estimatedDate = today.add(8, 'days').format()
      }
    }

    _this.equipmentLoadOptions().then(res => {
      _this.equipmentList = res
      // _this.onSearch(_this.filterData)
    }).finally(
      _this.isLoading = false
    )
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)

    // this.showInfo()

    this.$jQuery('#arrival_begin_date, #arrival_end_date, #departure_begin_date, #departure_end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })

    // <!-- Event snippet for Inscrição no eRoyal Royal Cargo conversion page -->
    if (Vue.config.productionTip === true) {
      this.$gtag.query('event', 'conversion', {
        'send_to': 'AW-868721578/-j2GCJjm5r0CEKrHnp4D'
      })
    }
  },
  methods: {
    equipmentLoadOptions () {
      let _this = this

      let promise = new Promise((resolve, reject) => {
        let filters = {
          'modality': _this.filterData.modality.id,
          'quotations': true
        }

        EquipmentService.getEquipments(filters).then(res => {
          let items = []

          for (let i in res.data.data) {
            let item = res.data.data[i]

            item.quantity = 0

            for (let sI in _this.filterData.equipments) {
              let selected = _this.filterData.equipments[sI]

              if (selected.id === item.id) {
                item.quantity = selected.quantity
                break
              }
            }
            items.push(item)
          }
          resolve(items)
        }).catch((error) => {
          reject(error)
        })
      })

      return promise
    },
    onSearch (value) {
      let _this = this
      _this.isLoading = true

      this.$router.push({ name: 'ProposalSearch', params: _this.filterData })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        CustomerService.getCustomers(filters).then(res => {
          _this.customerList = res.data.data
          resolve(_this.customerList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    employeeFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        EmployeeService.getFuncionarios(filters).then(res => {
          _this.employeeList = res.data.data
          resolve(_this.employeeList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    userFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        EmployeeService.getUsuarios(filters).then(res => {
          _this.userList = res.data.data
          resolve(_this.userList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    showInfo () {
      let notShowPeriodSituation = global.instanceApp.$Cookie.get('_proposal_not_show_period_situation_')

      if (notShowPeriodSituation !== 'true') {
        this.$modal.show(InfoModal, {}, {
          draggable: false,
          adaptive: true,
          scrollable: true,
          clickToClose: false,
          width: '35%',
          height: 'auto'
        })
      }
    },
    showModal () {
      this.$modal.show(TaskLog, {}, {
        draggable: false,
        adaptive: true,
        scrollable: true,
        clickToClose: false,
        width: '80%',
        height: 'auto'
      })
    },
    // Delete
    onDelete (proposal) {
      this.$alertSwal.modalConfirm('', 'Você tem certeza de que deseja excluir esta cotação.<br />Está certo desta ação?', 'warning', 'Sim', 'Não', (e) => this.delete(proposal)).then((result) => {
        if (result.value) {
          this.$alertSwal.toast('Cotação excluída com sucesso!')
        }
      })
    },
    onCloseAddContactsModal () {
    },
    onAddContacts (proposal) {
      let _this = this
      this.$modal.show(ContactModal, {
        proposal_id: proposal.id
      },
      {
        draggable: false,
        adaptive: true,
        scrollable: true,
        clickToClose: false,
        width: '48%',
        height: 'auto'
      },
      {
        'closed': _this.onCloseAddContactsModal
      })
    },
    async delete (proposal) {
      let _this = this
      _this.isLoading = true

      await ProposalService.delete(proposal.id).then(proposal => {
        _this.isLoading = false
        _this.$refs.vuetable.refresh()
      })
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id: object.id } }).href
    },
    buildFilters () {
      this.filter.users = (this.filters.filUsers) ? this.filters.filUsers.map(x => (x.id)) : []
      this.filter.employees = (this.filters.filEmployees) ? this.filters.filEmployees.map(x => (x.id)) : []
      this.filter.customer = (this.filters.filCliente) ? this.filters.filCliente.map(x => (x.id)) : []
      this.filter.id_status = null
    },
    handleLoadError (response) {
    },
    applyStatusFilter (idStatus) {
      event.preventDefault()
      this.filter.situacao = idStatus
      this.$refs.vuetable.refresh()
    },
    // Loader
    applyFilter () {
      this.employeeList = []
      this.userList = []
      this.customerList = []

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()
      this.$refs.vuetable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuichSidebar()

      this.filters.filCliente = []
      this.filters.filEmployees = []
      this.filters.filUsers = []
      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.$refs.vuetable.refresh()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    formatStatus (value) {
      if (!value) {
        return ' - '
      }

      let statusList = [
        {
          'id': 1,
          'label': this.$i18n.t('form.proposal.status.draft'),
          'color': '#ababab',
          'class': 'badge-draft'
        },
        {
          'id': 2,
          'label': this.$i18n.t('form.proposal.status.waiting'),
          'color': '#f1ba26',
          'class': 'badge-waiting'
        },
        {
          'id': 3,
          'label': this.$i18n.t('form.proposal.status.confirmed'),
          'color': '#5ec65f',
          'class': 'badge-confirmed'
        },
        {
          'id': 4,
          'label': this.$i18n.t('form.proposal.status.not_confirmed'),
          'color': '#F3614E',
          'class': 'badge-not-confirmed'
        },
        {
          'id': 7,
          'label': this.$i18n.t('form.proposal.status.cancel'),
          'color': '#F3614E',
          'class': 'badge-cancel'
        },
        {
          'id': 9,
          'label': this.$i18n.t('form.proposal.status.expired'),
          'color': '#F3614E',
          'class': 'badge-cancel'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return '<span class="badge ' + status.class + '">' + status.label + '</span>'
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()

      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    onRowClicked (row) {
      if (row.data.situacao === 1) {
        this.$router.push({ name: 'ProposalCreate', params: { id: row.data.id } })
      } else {
        this.$router.push({ name: 'ProposalView', params: { id: row.data.id } })
      }
    }
  },
  computed: {
    fieldsTable () {
      return [
        {
          name: 'referencia',
          title: this.$i18n.t('form.proposal.referencia'),
          sortField: 'referencia',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'cliente',
          title: this.$i18n.t('form.proposal.cliente'),
          sortField: 'cliente',
          visible: this.isMultipleCustomer,
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'data_criacao',
          title: this.$i18n.t('form.proposal.created_at'),
          sortField: 'data_criacao',
          formatter: (value) => this.$util.formatDateTime(value)
        },
        {
          name: 'referencia_cliente',
          title: this.$i18n.t('form.proposal.referencia_cliente'),
          sortField: 'referencia_cliente',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'data_previsao_embarque',
          title: this.$i18n.t('form.proposal.date'),
          sortField: 'data_previsao_embarque',
          formatter: (value) => this.$util.formatDate(value)
        },
        {
          name: 'origem',
          title: this.$i18n.t('form.proposal.origin'),
          sortField: 'origem',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'destino',
          title: this.$i18n.t('form.proposal.destination'),
          sortField: 'destino',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'situacao',
          title: this.$i18n.t('form.task.status.title'),
          sortField: 'situacao',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.formatStatus(value)
        },
        {
          name: 'actions',
          title: 'Ações',
          dataClass: 'text-center wrap-actions',
          titleClass: 'text-center'
        }
      ]
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return {
          filCliente: [],
          filUsers: [],
          filEmployees: []
        }
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    },
    showEmployeeFilter () {
      if (this.user) {
        let showEmployee = false
        if (this.user.roles.filter(role => role === 'ROLE_DIRECTOR').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_SUPER_ADMIN').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_FINANTIAL').length > 0) {
          showEmployee = true
        }

        return showEmployee
      } else {
        return false
      }
    },
    isMultipleCustomer () {
      return (this.user && this.user.multiple_customer && this.user.customers_allow_online_proposal_list.length > 1) || this.isSuperAdmin
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .btn-padding {
    padding: 0.55rem 0.30rem
  }

</style>

<style>
.badge-draft {
  background-color: #ababab !important;
  color: white !important;
}

.badge-draft:hover {
  background-color: #ababab !important;
  color: white !important;
}

.badge-waiting {
  background-color: #f1ba26 !important;
  color: white !important;
}

.badge-waiting:hover {
  background-color: #f1ba26 !important;
  color: white !important;
}

.badge-confirmed {
  background-color: #5ec65f !important;
  color: white !important;
}

.badge-confirmed:hover {
  background-color: #5ec65f !important;
  color: white !important;
}

.badge-not-confirmed {
  background-color: #F3614E !important;
  color: white !important;
}

.badge-not-confirmed:hover {
  background-color: #F3614E !important;
  color: white !important;
}

.badge-cancel {
  background-color: #F3614E !important;
  color: white !important;
}

.badge-cancel:hover {
  background-color: #F3614E !important;
  color: white !important;
}

.abaixo {
  background-color: white;
}
</style>
